body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

header {
  background-color: #333;
  color: #fff;
  text-align: center;
}

nav {
  background-color: #444;
  color: #fff;
  padding: 1em;
  text-align: center;
  overflow-x: auto;
}

nav a {
  color: #fff;
  text-decoration: none;
  margin: 0 1em;
}

section {
  padding: 2em;
}

.text-link {
  color: white
}

.text-link-black {
  color: black
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width: 500px;
}

.center-small {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
}

.left-align-image {
  display: block;
  margin-left: 5px;
  width: 50%;
  max-width: 500px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.menu-item {
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
  background-color: #bfcbe8;
  overflow: hidden;
  cursor: pointer;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.reference-item {
  max-width: 100%;
  width: 500px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em;
  margin-right: 1em;
  background-color: #bfcbe8;
}

.faicon {
  padding-left: 1em;
  padding-right: 1em;
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  margin-top: auto;
  width: 100%;
}